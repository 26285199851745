.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 40px;
}

.footer {
  margin-top: auto;
  text-align: center;
  font-size: 11px;
  line-height: 1.2em;
  .powered-by {
    color: #b7b8bd;
  }
}
