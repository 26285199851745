$heading-font-family: "Poppins", sans-serif;
$body-font-family: "DM Sans", sans-serif;

// COMMON STYLES
// Margin bottom
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}

body {
  margin: 0;
  font-family: $body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.2em;
  font-style: normal;
  letter-spacing: 0px;
}
a {
  text-decoration: none;
}
.thinText {
  font-weight: 400 !important;
}
.mediumText {
  font-weight: 600 !important;
}
.boldText {
  font-weight: 700 !important;
}
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f5f7;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border-width: 7px;
  border-style: solid;
  border-color: white;
  margin: auto;
}
