.done-icon {
  margin-bottom: 24px;
}

.spinner {
  display: inline-block;
  position: relative;
  background-color: #0364ff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: conic-gradient(transparent, #0364ff);
  animation: rotate 1s linear infinite;
  .mask,
  .head {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  .mask {
    width: 50px;
    height: 50px;
    top: 5px;
    left: 5px;
    background-color: #ffffff;
  }
  .head {
    height: 5px;
    width: 5px;
    background-color: #0364ff;
    top: 0;
    left: 26px;
  }
  &.spinner--dark {
    .mask {
      background-color: #252529;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.ping-container {
  display: inline-flex;
  position: relative;

  .ping-animate {
    background-color: #0364ff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .ping-content {
    position: relative;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #0364ff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 34px;
      height: auto;
    }
  }

  &.theme--dark {
    .ping-animate {
      background-color: #fff;
    }
    .ping-content {
      background-color: #fff;
    }
  }
}

@keyframes ping {
  75%,
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
